#headerstripe {
  position: sticky;
  top: 0;
  height: var(--header-stripe-height);
  font-size: 90%;
  color: white;
  text-align: center;
  background-color: $blue;
  padding-block: .1rem;
  box-shadow: 0 2px 4px #aaa;
  margin-bottom: calc(var(--header-stripe-height) * -1);
  z-index: 51;

  a {
    color: white;
    text-decoration: underline;
  }
}

.header-container {
  position: relative;
  width: 100%;
  background: url(/assets/images/bg_header_gradient.png) repeat-y;
  background-size: contain;
  border-top: var(--header-stripe-height) solid $blue;
  box-shadow: 0 0 8px #525151;
  z-index: 50;

  @media (max-width: $tablet) {
    position: sticky;
    top: 0;
  }
}


#header {
  padding: .5rem 1rem;

  @media (max-width: $tablet) {
    height: var(--header-height-mobile);
    display: flex;
    align-items: center;
    justify-content: space-between;

    a#header-logo {
      align-self: normal;
    }
  }

  @media (min-width: $tablet) {
    padding: 1.25rem 1rem 0;
  }

  .search {
    position: absolute;
    right: 7.5%;
    top: 28px;

    @media (max-width: $desktop) {
      display: none;
    }

    input {
      background: url('/assets/images/search_icon.png') no-repeat 5px 4px;
      border: 1px solid #7b7b7b;
      font-size: 90%;
      outline: none;
      padding: 4px 4px 4px 30px;
    }
  }
}


#header-logo {
  > img {
    max-height: 100%;
    max-width: 100%;
  }

  @media (min-width: $tablet) {
    margin: 0 auto 1rem;
    display: block;
    max-width: 200px;
  }
}


header#header .main-nav {
  display: none;

  @media only screen and (min-width: $tablet) {
    display: block;

    li:last-child a {
      margin-right: 0;
    }
  }

  @media (min-width: $tablet) and (max-width: $desktop) {
    /* 80px is the width of the hamburger menu button, which floats on the right */
    margin-right: 80px;

    li {
      &.tablet {
        display: inline-block;
      }

      ul {
        display: none;
      }
    }

    ul.tertiary {
      display: block !important;
      left: 0 !important;
      position: relative !important;

      a {
        background-color: rgba(123, 166, 201, 0.95);
      }
    }
  }

  a {
    color: black;
    display: inline-block;
    font-family: 'Noto Sans', sans-serif;
    font-weight: 700;
    font-size: 14px;
    min-width: 120px;
    padding: 5px .8%;
    text-align: center;
    text-decoration: none;
    text-transform: uppercase;
  }

  ul.primary {
    display: flex;
    justify-content: center;

    > li {
      > a {
        min-width: initial;
        padding: 5px 10px;
      }
      > a:not(:last-child) { margin-right: 1rem }

      ul { display: none }

      &.selected a {
        background-color: $blue;
        color: #ffffff;
        text-decoration: none;
      }

      @media (min-width: $tablet) {
        @media (max-width: $desktop) {
          &:not(:first-child) .secondary {
            margin-left: 0 !important;
          }
        }

        &:hover {
          a {
            background-color: $blue;
            color: #ffffff;
            text-decoration: none;
          }

          ul.secondary {
            display: block;
            margin-left: -1px;
            padding: 0;
            position: absolute;

            li {
              display: block;
              margin: 0;
              position: relative;
              width: 190px;
              z-index: 10;

              &:hover {
                a {
                  background-color: rgba($grey, .95);
                }

                ul.tertiary {
                  display: block;
                  left: 190px;
                  position: absolute;
                  top: 0;

                  li:hover a {
                    background-color: rgba($blue, .95);
                  }
                }
                .arrow {
                  background-position: -236px -10px;
                  height: 16px;
                  width: 11px;
                }
              }

              a {
                background-color: rgba($blue, .95);
                padding: 5px 10px;
                text-align: left;
                text-transform: capitalize;
                width: 100%;
              }

              .arrow {
                background: url('/assets/images/achorn_sprite.png') no-repeat;
                background-position: -247px -10px;
                display: block;
                height: 16px;
                position: absolute;
                right: 8px;
                top: 10px;
                width: 16px;
              }
            }
          }
        }
      }

      @media (min-width: $tablet) and (max-width: $desktop) {
        &.tablet {
          display: none;
        }
      }
    }
  }

  nav ul li {
    display: inline;
  }

  .oldie nav a {
    margin: 0 0.7%;
  }
}


header#header .actions {
  color: $grey;
  display: flex;

  @media (max-width: $tablet) {
    > li > a .text { display: none !important }

    .phone-button, .contact-button {
      margin-right: 8vw;
    }
  }

  @media (min-width: $tablet) and (max-width: $desktop) {
    float: right;
    margin-top: -30px; /* 30px is the height if the hamburger button */
  }

  @media (min-width: $desktop) {
    display: none;
  }

  > li {
    @media (min-width: $tablet) and (max-width: $desktop) {
      width: auto;
      text-align: center;
      margin: 0;
    }

    > a {
      .img, .text {
        display: inline-block;
        padding: 2px 8px;
        vertical-align: middle;
      }
      .text {
        font-size: 15px;
        text-transform: uppercase;
      }
    }
  }

  .navicon {
    .img {
      background: url('/assets/images/achorn_sprite.png') no-repeat;
      background-position: -536px -11px;
      display: inline-block;
      height: 18px;
      width: 24px;
    }

    .text {
      text-transform: uppercase;
    }
  }

  .phone-button, .contact-button {
    @media only screen and (min-width: $tablet) {
      display: none;
    }
  }

  .navicon-button {
    > .navicon {
      cursor: pointer;
      display: block;
      float: right;
      position: relative;

      @media (min-width: $tablet) and (max-width: $desktop) {
        margin-top: -5px;
        padding-bottom: 5px;
      }
    }
  }
}


#mobile-nav {
  --menu-item-padding-x: .5rem;

  display: none;
  background: rgba(0, 93, 144, 0.95);
  width: 100%;
  height: calc(100vh - var(--top-overlay-margin));
  overflow-y: scroll;
  right: -100%;
  padding: 0 var(--menu-item-padding-x);
  position: fixed;
  top: var(--top-overlay-margin);
  z-index: 50;

  @media (min-width: $tablet) and (max-width: $desktop) {
    top: 120px;

    ul.primary > li:not(.tablet) {
      display: none;
    }
  }

  form {
    clear: both;
    height: 40px;
    margin: 10px 0 0 var(--menu-item-padding-x);
    width: 55%;
    position: absolute;

    input {
      border: 1px solid lighten($grey, 25%);
      height: 30px;
      width: 100%;
    }
  }

  ul {
    clear: both;
  }

  > ul {
    @media (min-width: $tablet) and (max-width: $desktop) {
      top: 117px;
    }
  }

  > ul > li.parent {
    padding: .3rem 0;
    border-bottom: 1px solid rgba(255, 255, 255, 0.3);

    > a {
      padding-right: 100px;
      text-transform: uppercase;
    }
  }

  li {
    clear: both;
    display: block;
    position: relative;
    width: 100%;

    a {
      color: #fff;
      display: block;
      font-size: 1rem;
      padding-left: var(--menu-item-padding-x);
      text-transform: capitalize;
      width: 100%;

      + .arrow + ul {
        margin-left: 15px;
      }
    }

    ul {
      display: none;
    }

    .arrow {
      background: url('/assets/images/achorn_sprite.png') no-repeat;
      background-position: -236px -10px;
      display: block;
      height: 16px;
      position: absolute;
      right: var(--menu-item-padding-x);
      top: 10px;
      width: 11px;

      &.active {
        background-position: -247px -10px;
        width: 16px;
      }
    }

    .dropdown {
      display: none;

      a {
        background-color: lighten($grey, 20%);
      }
    }
  }

  /* Dropdown submenu */
  .secondary > li {
    padding: .15rem 0;
  }

  .nav-wrapper {
    float: right;
    padding: 10px var(--menu-item-padding-x) 17px 10px;

    @media (min-width: $tablet) and (max-width: $desktop) {
      padding-right: 0;
    }

    .navicon {
      cursor: pointer;
      float: right;
      text-indent: -50px;

      .text, .img {
        padding: 2px 8px;
        vertical-align: middle;
      }

      .text {
        color: #fff;
        font-size: 15px;
        text-transform: uppercase;
      }

      .img {
        display: inline-block;
        background: url('/assets/images/achorn_sprite.png') no-repeat -567px -10px;
        height: 18px;
        width: 18px;
      }
    }
  }
}


.overlay {
  background-color: #000;
  display: none;
  height: 100%;
  left: 0;
  margin-top: var(--top-overlay-margin);
  position: absolute;
  top: 0;
  width: 100%;
  z-index: 45;

  @include opacity75;

  @media (min-width: $tablet) and (max-width: $desktop) {
    margin-top: 120px;
  }
}
