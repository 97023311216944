.footer-container {
  background: $blue;
  -webkit-box-shadow: inset 0 5px 10px #444;
  -moz-box-shadow: inset 0 5px 10px #444;
  box-shadow: inset 0 5px 10px #444;
  position: absolute;
  bottom: 0;
  width: 100%;
  height: 134px;

  @media (max-width: $tablet) {
    height: auto;
  }

  footer {
    color: #fff;
    position: relative;
    max-width: 960px;
    height: 134px;
    padding: 14px 10px 5px;
    overflow: hidden;

    @media (max-width: $tablet) {
      height: auto;
    }

    h3 {
      @media (max-width: $tablet) {
        display: none;
      }
    }
  }

  .utility-nav {
    z-index: 2;

    @media (max-width: $mobile-h) {
      li {
        padding: 0 !important;
      }
      li:first-child {
        width: 34%;
      }
      li:nth-child(2) {
        width: 24%;
      }
      li:last-child {
        width: 39%;
      }
    }

    @media (max-width: $tablet) {
      margin: 15px 0;
      width: 100%;
    }

    @media (min-width: $tablet) {
      position: absolute;
      right: 0;
      top: 20px;
    }

    li {
      display: inline-block;
      border-right: 1px solid #b8b8b8;
      text-align: center;
      line-height: 100%;
      padding: 0 10px;

      @media (max-width: $tablet) {
        width: 32%;
      }

      a {
        text-transform: uppercase;
        color: #b8b8b8;
        font-size: 85%;
      }
    }
    li:last-child {
      border-right: 0;
    }
  }
  .copyright {
    clear: both;
    font-size: 85%;
    color: #b8b8b8;

    @media (min-width: $tablet) {
      margin-top: 5px;
    }

    @media (max-width: $tablet) {
      text-align: center;
    }
  }

  .footer-address {
    text-align: center;
    margin-top: 1rem;

    @media (max-width: $tablet) {
      display: none;
    }

    li {
      display: inline;
      padding-left: 25px;
      font-size: 85%;
      color: #b8b8b8;
      text-transform: uppercase;

      &:first-child {
        padding-left: 0;
      }
    }
  }

  .youtube {
    img {
      width: 100px;

      margin: 0 auto;
      display: block;

      @media (min-width: $tablet) {
        position: absolute;
        right: 0;
        top: 30px;
      }
    }
  }
}

