.learn-more {
  @include learn-more;
}


.video-template .main {
  padding-bottom: 0 !important;
}
.video-container {
  border-bottom: 2px solid #c0bfc0;
  -webkit-box-shadow: 0 5px 10px #c0bfc0;
  -moz-box-shadow: 0 5px 10px #c0bfc0;
  box-shadow: 0 5px 10px #c0bfc0;
}
.video-arrow-sep {
  display: block;
  width: 76px;
  margin: 0 auto;
}
.video-gallery-heading-container {
  margin: 25px auto;
  max-width: 960px;
  position: relative;
}
.video-gallery-heading-container h1 {
  margin: 0 auto;
  max-width: none !important;
  text-align: center;
}
.video-gallery-container {
  background-color: $blue;
  margin-top: 25px;
}
.video-gallery-container h3 {
  color: #fff;
  border-bottom: 1px solid #fff;
  padding-bottom: 5px;
}
.video-gallery-container h3:not(:first-child) {
  padding-top: 25px;
}
.video-gallery-container p {
  color: #fff;
}
.video-gallery-container .equipment-top {
  padding-top: 50px;
}
.video-gallery-container .thumbs-container {
  margin: 25px auto;
  max-width: 960px;
  position: relative;
}
@media (max-width: 1024px) {
  .video-gallery-container .thumbs-container {
    padding: 0 15px;
  }
}
.video-gallery-container .thumbs-container ul li {
  color: #fff;
  max-width: 235px;
  display: inline-block;
  vertical-align: top;
  margin: 15px 0;
}
@media (max-width: 480px) {
  .video-gallery-container .thumbs-container ul li {
    max-width: 100%;
  }
}
.video-gallery-container .thumbs-container ul li p {
  color: #fff;
  font-weight: lighter;
  font-size: 12px;
  line-height: 16px;
  border-top: 1px solid #fff;
  padding-top: 5px;
  margin-top: 3px;
}
.vid-thumb-title {
  display: inline-block;
  //font-size: 18px;
  line-height: 1;
  color: #fff;
  vertical-align: bottom;
}
.thumb-title-container {
  display: inline-block;
  width: auto;
  height: 36px;
  background: url('/assets/images/arrow-up.png') no-repeat left center;
  background-size: 15px;
  padding: 0 0 0 25px;
  margin-top: 10px;
}
.thumb-title-container:hover {
  background-image: url('/assets/images/arrow-over.png');
}
.vid-thumb-caption {
  font-size: 12px;
  line-height: 18px;
  height: 10px;
  color: #fff !important;
  margin-top: 5px;
  margin-bottom: 10px;
}
.play-arrow {
  display: inline-block;
  opacity: 0;
  position: relative;
  top: 50%;
  -webkit-transform: translateY(-50%);
  -ms-transform: translateY(-50%);
  transform: translateY(-50%);
  width: 15%;
}
.video-centered-background {
  background: no-repeat center center #9a989a;
  background-size: cover;
  width: 235px;
  height: 135px;
  text-align: center;
}
@media (max-width: 480px) {
  .video-centered-background {
    width: 100%;
    background-size: cover !important;
    height: 200px;
  }
}
.thumb-li {
  cursor: pointer;
}
