form { margin-block: 1rem }

// "form" is added to increase specificity since
// there is a font style for "input" downstream in the main.scss.
form textarea, form input, form button {
  font-family: 'Noto Sans', sans-serif;
  font-weight: 300;
  font-size: 1.25rem;
}

input[type="text"], input[type="email"], textarea {
  width: 100%;
  margin-block: .5rem;
  padding: .5rem;
  border: 1px solid $grey;
}

label ~ input[type="text"],
label ~ input[type="email"],
label ~ textarea {
  margin-top: 0;
}

input[type="checkbox"] {
  width: 1rem;
  height: 1rem;
  margin: 0 .5rem 0 0;
}

input[type="submit"], button[type="submit"] {
  display: block;
  font-weight: bold;
  color: #fff;
  padding: 0.5rem min(4rem, 5vw);
  background-color: $blue;
  border: 0;
  margin-top: 1.5rem;
  margin-inline: auto;
  cursor: pointer;
  transition: background-color .2s;

  &:hover { background-color: lighten($blue, 5%) }
  &:disabled { opacity: .5 }
}


// Freeform styles overrides
@media (max-width: 425px) {
  form .freeform-row {
    display: block;
  }
}

form .freeform-row .freeform-column .freeform-label { font-weight: normal }


.sidebar-form {
  background-color: $grey;
  padding: 1rem .75rem;
  margin-top: 0;

  .bottom-half { height: 100% }
  .spf { display: none !important }
}


//
// Notifications
//
.form-errors p, .form-success p {
  font-size: 1.2em !important;
}
.form-success p {
  color: mediumseagreen;
  margin-top: 2rem;
}

.form-errors p, .errors.help-block li {
  color: indianred
}

.errors.help-block li {
  list-style: none;
  margin-left: 0;
  padding-top: 0;
}
.has-error {
  input, textarea { border: 1px solid indianred }
}


// Styles for the legacy forms that are still on the site (Contact Us and in the Sidebar)
.form-response {
  display: none;

  &.success { color: mediumseagreen }
  &.error { color: indianred }
}
.form-response {
  font-size: 1.2em;
  text-align: center;
  margin-top: 2rem;
}
.sidebar .form-response {
  padding: 1rem;
  border: 1px solid #9a989a;
  border-top: none;
  margin-top: 0;
}
