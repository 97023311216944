.breadcrumbs {
  color: $grey;
  font-size: 12px;
  margin: 1rem 0 1rem;

  a { color: $grey; }
}


.main-container {
  overflow: hidden;

  @media (max-width: $tablet) {
    .main.home, .main.applications {
      padding-bottom: 37px;
    }
  }

  .main:not(.home):not(.applications) {
    padding-bottom: 50px;
  }

  h1 {
    line-height: 55px;
    margin-bottom: 15px;
    color: $blue;

    @media (max-width: $mobile-h) {
      word-wrap: break-word !important;
      font-size: 39px !important;
    }
  }

  p {
    font-size: 14px;
    padding-bottom: 10px;
  }
}

.system-image,
.line-image {
  background-repeat: no-repeat;
  background-size: 100%;
  height: 400px;

  @media (max-width: 850px) {
    height: 345px;
  }

  @media (max-width: $tablet) {
    height: 280px;
  }

  @media (max-width: 550px) {
    height: 230px;
  }

  @media (max-width: $mobile-h) {
    height: 200px;
  }

  @media (max-width: 380px) {
    height: 165px;
  }
}

.system-image {
  height: 480px;
  max-width: 930px;
  width: 90vw;

  @media (max-width: 950px) {
    height: 440px;
  }

  @media (max-width: 850px) {
    height: 395px;
  }

  @media (max-width: $tablet) {
    height: 335px;
  }

  @media (max-width: 550px) {
    height: 255px;
  }

  @media (max-width: $mobile-h) {
    height: 225px;
  }

  @media (max-width: 380px) {
    height: 175px;
  }
}

.header-image {
  border-top: 5px solid $blue;
  border-bottom: 3px solid $blue;
  -webkit-box-shadow: 0 5px 10px #c0bfc0;
  -moz-box-shadow: 0 5px 10px #c0bfc0;
  box-shadow: 0 5px 10px #c0bfc0;

  width: 100%;
  background-repeat: no-repeat;
  background-position: 30%;
  height: 300px;
  background-size: 420%;

  @media (min-width: 590px) {
    background-size: 240%;
  }

  @media (min-width: 780px) {
    background-size: 180%;
  }

  @media (min-width: 930px) {
    background-size: 150%;
  }

  @media (min-width: 1090px) {
    background-size: 130%;
  }

  @media (min-width: 1290px) {
    background-size: 110%;
  }
}

.content,
.equipment .item-list,
.equipment-top,
.equipment-capacity .constrain,
.gallery .image-list {
  margin: 0 auto;
  position: relative;
}
.content,
.equipment-top {
  max-width: 1240px;
}
.equipment .item-list,
.equipment-capacity .constrain,
.gallery .image-list {
  max-width: 960px;
}

.top {
  position: relative;
  padding: 0 10px 20px;

  &.applications {
    border-bottom: 5px solid #c0bfc0;
    -webkit-box-shadow: 0 5px 10px #c0bfc0;
    -moz-box-shadow: 0 5px 10px #c0bfc0;
    box-shadow: 0 5px 10px #c0bfc0;
  }

  .left-image, .right-image {
    @media (max-width: 1230px) {
      display: none;
    }
  }

  &.popcorn {
    .left-image {
      position: absolute;
      z-index: -99;
      left: 0;
      top: -5px;
      background: url('/assets/images/applications/popcorn-left.png') no-repeat;
      width: 213px;
      height: 408px;
    }
    .right-image {
      position: absolute;
      z-index: -99;
      right: 0;
      bottom: 0;
      background: url('/assets/images/applications/popcorn-right.png') no-repeat;
      width: 300px;
      height: 520px;
    }
  }

  &.roasted-nuts {
    .left-image {
      position: absolute;
      z-index: -99;
      left: 0;
      top: -5px;
      background: url('/assets/images/applications/roasted-nuts-left.png') no-repeat;
      width: 248px;
      height: 509px;
    }
    .right-image {
      position: absolute;
      z-index: -99;
      right: 0;
      bottom: 0;
      background: url('/assets/images/applications/roasted-nuts-right.png') no-repeat;
      width: 248px;
      height: 506px;
    }
  }

  &.blanched-peanuts {
    .left-image {
      position: absolute;
      z-index: -99;
      left: 0;
      top: -5px;
      background: url('/assets/images/applications/blanched-peanuts-left.png') no-repeat;
      width: 300px;
      height: 520px;
    }
    .right-image {
      position: absolute;
      z-index: -99;
      right: 0;
      bottom: 0;
      background: url('/assets/images/applications/blanched-peanuts-right.png') no-repeat;
      width: 300px;
      height: 520px;
    }
  }

  &.japanese-peanuts {
    .left-image {
      position: absolute;
      z-index: -99;
      left: 0;
      top: -5px;
      background: url('/assets/images/applications/japanese-peanuts-left.png') no-repeat;
      width: 300px;
      height: 520px;
    }
    .right-image {
      position: absolute;
      z-index: -99;
      right: 0;
      bottom: 0;
      background: url('/assets/images/applications/japanese-peanuts-right.png') no-repeat;
      width: 301px;
      height: 520px;
    }
  }

  &.nut-butter {
    .left-image {
      position: absolute;
      z-index: -99;
      left: -5px;
      top: -10px;
      background: url('/assets/images/applications/nut-butter-left.png') no-repeat;
      width: 300px;
      height: 520px;
    }
    .right-image {
      position: absolute;
      z-index: -99;
      right: 0;
      bottom: 0;
      background: url('/assets/images/applications/nut-butter-right.png') no-repeat;
      width: 300px;
      height: 520px;
    }
  }

  &.corn-tortillas-chips {
    .left-image {
      position: absolute;
      z-index: -99;
      left: 0;
      top: -5px;
      background: url('/assets/images/applications/corn-tortillas-chips-left.png') no-repeat;
      width: 248px;
      height: 509px;
    }
    .right-image {
      position: absolute;
      z-index: -99;
      right: 0;
      bottom: 0;
      background: url('/assets/images/applications/corn-tortillas-chips-right.png') no-repeat;
      width: 304px;
      height: 544px;
    }
  }

  &.pellets {
    .left-image {
      position: absolute;
      z-index: -99;
      left: 0;
      top: -5px;
      background: url('/assets/images/applications/pellets-left.png') no-repeat;
      width: 300px;
      height: 520px;
    }
    .right-image {
      position: absolute;
      z-index: -99;
      right: 0;
      bottom: 0;
      background: url('/assets/images/applications/pellets-right.png') no-repeat;
      width: 300px;
      height: 520px;
    }
  }
}


// .content is a wrapper of the main content area.
.content {
  display: flex;
  flex-wrap: wrap;
  justify-content: space-between;
  margin-bottom: 15px;

  .breadcrumbs,
  .under-breadcrumb-menu {
    flex-basis: 100%;
  }

  .under-breadcrumb-menu {
    margin-bottom: 1rem;

    ul li {
      display: inline-block;
      margin: 3px -1px;
      padding: 5px 10px;
      font-size: 13px;
      background-color: $grey;

      a {
        color: white;
      }

      &:hover, &.active {
        background-color: $blue;

        a {
          color: #fff;
        }
      }
    }
  }

  .description {
    max-width: 100%;

    h3 {
      margin: 30px 0 10px;
      color: $blue;

      &:first-child { margin-top: 0; }
    }

    a { text-decoration: underline; }

    .new-callout {
      clear: both;
      float: right;
      width: 45%;
      padding: 10px;
      color: $blue;
      font-family: 'Noto Sans', sans-serif;
      font-weight: 300;
      font-size: 26px;

      @media (max-width: $tablet) {
        display: none;
      }
    }

    .menu {
      padding-top: 25px;

      ul {
        margin: 0;
        list-style-type: none;
      }

      li {
        list-style-type: none;
        width: 100%;
        margin: 2px 0;
        position: relative;

        &:hover {
          > a[href] {
            color: #656565;

            + .arrow {
              background-position: -633px -10px;

              &.right {
                background-position: -621px -10px;
              }
            }
          }
        }

        a {
          background-color: $blue;
          text-decoration: none;
          color: #ffffff;
          font-size: 17px;
          padding: 5px 5px 5px 10px;
          display: inline-block;
          width: 100%;
        }

        .arrow {
          display: block;
          position: absolute;
          top: 10px;
          right: 10px;
          background: url('/assets/images/achorn_sprite.png') no-repeat;
          background-position: -247px -10px;
          height: 16px;
          width: 16px;

          &.right {
            background-position: -236px -10px;
            width: 11px;
          }
        }
      }

      div.secondary {
        padding: 5px 25px;
      }

      .secondary {
        background-color: #f0f0f0;

        li {
          display: inline-block;
          width: 45%;

          &:hover {
            a {
              color: #81aed3;
            }

            .arrow {
              background-position: -609px -10px !important;
            }
          }

          a {
            background-color: #f0f0f0;
            color: #656565;
            padding-left: 30px;
          }

          .arrow {
            background-position: -621px -10px;
            right: auto;
            left: 10px;
            width: 11px;
          }
        }
      }
    }
  }

  .sidebar {
    flex-grow: 1;
  }

  .description + .sidebar {
    margin-top: 2rem;
  }

  ul {
    list-style-type: disc;

    li {
      list-style-type: disc;
      margin-left: 20px;
      font-size: 14px;
      line-height: 18px;
      padding-top: 6px;
    }
  }

  @media (min-width: $desktop) {
    .description {
      flex: 0 0 68%;
      max-width: 68%;
    }
    .sidebar {
      flex: 0 0 28%;
      max-width: 28%;
    }
    .description + .sidebar {
      margin-top: 0;
    }
  }
}

.equipment-top .content {
  @media (min-width: $desktop) {
    .description {
      //flex-basis: auto;
    }
    .description + .sidebar {
      //margin-left: 0;
    }
    .sidebar {
      //max-width: none;
    }
  }
}

.sidebar {
  h3 {
    position: relative;
    display: block;
    font-family: 'Noto Sans', sans-serif;
    font-weight: normal;
    font-size: 25px;
    line-height: 1;
    color:  #fff;
    background-color: $grey;
    padding: 2px 80px 4px 8px;

    @media (max-width: $desktop) {
      font-size: 25px;
    }

    small {
      text-transform: uppercase;
      font-weight: 300;
      font-size: 16px;
    }

    span {
      width: 56px;
      height: 56px;
      text-align: center;
      line-height: 56px;
      font-size: 54px !important;
      color: $white;
      font-family: 'Noto Sans', sans-serif;
      font-weight: bold;
      background-color: $blue;
      position: absolute;
      top: 0;
      right: 0;
    }
  }

  .links {
    .jump {
      margin-top: 8px;
      width: 100%;
      background: $blue;
      display: inline-block;
      color: #fff;
      padding: 12px 56px 12px 15px;
      position: relative;
      font-family: 'Noto Sans', sans-serif;
      font-size: 20px;

      .icon {
        display: block;
        position: absolute;
        right: 0;
        top: 0;
        height: 100%;
        width: 56px;
        background: $grey;

        .arrow {
          background: url('/assets/images/achorn_sprite.png') no-repeat;
          background-position: -264px -11px;
          height: 17px;
          width: 27px;
          position: absolute;
          top: 36%;
          right: 15px;
        }
      }
    }
  }

  .equipment-menu,
  .applications-menu {
    padding: 45px 15px;

    ul li {
      text-decoration: none;
      list-style-type: none;
      font-size: 16px;

      .secondary li {
        list-style-type: disc;
        font-size: 14px;
        margin-bottom: 0;
        margin-left: 15px;
      }
    }
  }
}

a[name="equipment"] {
  visibility: hidden;
  height: 0;
  width: 0;
  position: absolute;
}

h2.equipment-title {
  padding: 30px;
  margin-top: 20px;
  font-size: 55px;
  line-height: 55px;

  @media (max-width: $tablet) {
    font-size: 45px;
    line-height: 45px;
  }
}

.equipment, .gallery {
  background-color: #5389c0;
  color: #fff;
  overflow: hidden;

  .item-list, .image-list {
    margin: 22px auto;

    @media (min-width: $tablet) and (max-width: $desktop) {
      width: 650px;

      .item:nth-child(2n -4),
      .image:nth-child(2n -4) {
        margin-left: 40px;
      }
    }
  }

  .item, .image {
    width: 30%;
    float: left;
    padding: 20px 0 40px;

    &:not(:nth-child(3n - 5)) {
      margin-left: 5%;
    }

    &:nth-child(3n - 5) {
      clear: both;
    }

    @media (max-width: $tablet) {
      margin: 0 auto;
      float: none;
    }

    @media (max-width: $desktop) {
      width: 300px;

      &:not(:nth-child(3n - 5)) {
        margin-left: auto;
      }

      &:nth-child(3n - 5) {
        clear: none;
      }
    }

    h3 {
      color: #fff;
      border-bottom: 1px solid #fff;
      padding: 24px 5px 10px;
      font-size: 22px;
      line-height: 22px;
      height: 78px;
    }

    .description {
      height: 150px;
      font-size: 14px;
      margin-top: 7px;

      p {
        color: #fff;
      }
    }

    .equipment-image {
      height: 385px;
      width: 300px;
      text-align: center;
      background: #fff;

      img {
        max-width: 100%;
        max-height: 100%;
        position: relative;
        top: 50%;
        -webkit-transform: translateY(-50%);
        -moz-transform: translateY(-50%);
        -ms-transform: translateY(-50%);
        -o-transform: translateY(-50%);
        transform: translateY(-50%);
      }
    }
  }
}

.equipment-top {
  h1 {
    font-size: 47px;
    line-height: 1;
    margin: 15px 0;
    border-bottom: 1px solid #656565;
  }

  .header {
    height: 136px;
    position: relative;
    margin-top: 1rem;

    &:after {
      content: '';
      display: block;
      clear: both;
    }
  }
  .description {

    .image {
      text-align: center;
    }
  }

  .content-wrapper {
    width: 100%;
    border-top: 1px solid #3b6682;

    .content-left,
    .content-right {
      width: 50%;
      padding: 20px;
      float: left;
    }

    .content-right {
      background-color: #3b6682;
      color: #fff;

      h3 {
        margin-bottom: 10px;
        font-size: 30px;
      }

      p {
        font-size: 15px;
        color: #fff;
        margin-bottom: 20px;
        line-height: 19px;
      }
    }
  }
}

.equipment-options {
  padding-top: 20px;
  width: 100%;

  h3 {
    font-size: 33px;
  }
}

.equipment-sections,
.equipment-capacity {
  clear: both;
  padding: 20px 0 70px;
  width: 100%;

  h3 {
    text-align: left;
    font-size: 33px;
  }

  h3 + p {
    background: $blue;
    color: #fff;
    padding: 5px 10px;
    margin-top: 15px;
    font-size: 18px;
  }

  table {
    width: 100%;
    background-color: #f0f0f0;

    thead {
      font-weight: bold;
    }

    tr {
      border-bottom: 2px solid #fff;
    }

    th, td {
      width: auto;
      text-align: center;
      padding: 5px 10px;
      color: #656565;
      font-size: 14px;

      &:first-child {
        width: 17%;
        text-align: left;
        padding-left: 20px;
      }

      p {
        color: #656565;
        padding: 0;
      }
    }
  }

  p.small {
    font-size: 11px;
    color: #656565;
    padding: 5px 0;

    &.asterisk:before {
      content: '* ';
      margin-left: -7px;
    }
  }

  & + img.grey-arrow-drop {
    margin-top: -5px;
  }
}

.equipment-sections {
  p {
    background: $blue;
    margin-top: 15px;
    font-family: 'Noto Sans', sans-serif;
    font-size: 18px;
    margin-left: 5%;
    padding: 5px 10px;
    color: white;
  }

  table td {
    text-align: left;

    &:first-child {
      width: 5%;
      text-align: left;
      padding-left: 20px;
      background-color: #00609C;
      color: #FFF;
      border-right: 2px solid #FFF;
    }
    &:nth-child(2) {
      width: 30%;
      border-right: 2px solid #FFF;
    }
    &:nth-child(3) {
      color: #999999;
    }
  }
}

h2.gallery-title {
  margin-bottom: 20px;
  margin-top: 85px;
  font-size: 55px;
}

section.gallery {
  .image-list {
    overflow: hidden;

    .image {
      max-height: 300px;
      border: 1px solid #fff;

      img {
        position: relative;
        top: -60px;
        text-align: center;
      }
    }
  }
}

.trade-show-calendar .description p {
  padding: 20px 0 10px;
}

.search-results .result,
.news .story {
  clear: both;

  &:not(:first-child) {
    border-top: 1px solid #676767;
    margin-top: 15px;
    padding-top: 5px;
  }

  p {
    padding: 0;
  }
}

.news .story {
  .date, .body {
  }
}

.trade-show-calendar .description .menu ul li > a {
  &:after {
    height: 16px;
    width: 11px;
    content: " ";
    position: absolute;
    background: url(/assets/images/achorn_sprite.png) -236px -10px no-repeat;
    right: 10px;
    margin-top: 4px;
  }

  &:hover {
    &:after {
      background-position: -621px -10px;
    }
  }

}

.contact-us {
  .sidebar {
    flex-grow: 0;

    h3 {
      background: #fff;
      color: #000;
      padding: 10px 0;
      font-size: 26px;
    }

    .indent {
      margin-left: 30px;
    }
  }
}

.label {
  font-size: 1.2rem;
  font-family: 'Noto Sans', sans-serif;
  font-weight: bold;
  color: #00609c;
}

.video-caption {
  color: #272727;
  display: inline-block;
  font-size: 12px;
  font-style: italic;
  margin-bottom: 10px;
}

.popcorn .video-caption {
  margin-bottom: 38px;
}

.generations-of-craftsmanship .description img {
  margin-bottom: 10px;
}

.doug-horn-tribute .description img {
  margin-bottom: 20px;
}

.ac-horn-manufacturing-hires-first-ever-coo h1 {
  font-size: 52px;
}
