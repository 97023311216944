.home {
  position: relative;

  .news {
    visibility: hidden;
    position: absolute;
    z-index: 1;
    height: 30px;
    width: 100%;
    background-color: $blue;

    @media (max-width: $tablet) {
      position: relative;
    }

    .alert {
      display: flex;
      color: #fff;
      max-width: 960px;
      margin: 0 auto;
      position: relative;
      padding-left: 10px;
      padding-top: 4px;

      @media (max-width: $desktop) {
        left: 30px;
      }

      a {
        color: #fff;
        text-decoration: underline;
      }

      span {
        color: #fff;
      }
    }

    .close {
      color: #fff;
      left: -20px;
      top: 8px;
      display: block;
      position: absolute;
      text-indent: -9999em;
      background-image: url(/assets/images/achorn_sprite.png);
      background-position: -585px -13px;
      height: 19px;
      width: 18px;
      text-decoration: none;

      &:hover {
        cursor: pointer;
      }
    }
  }
}

#leadimage {
  overflow: hidden;
  border-top: 5px solid $blue;
  border-bottom: 5px solid $blue;
  -webkit-box-shadow: 0 5px 10px #aaa, inset 0 1px 4px #272727;
  -moz-box-shadow: 0 5px 10px #aaa, inset 0 1px 4px #272727;
  box-shadow: 0 5px 10px #aaa, inset 0 1px 4px #272727;
}

.blue-arrow-drop, .grey-arrow-drop {
  width: 76px;
  margin: 0 auto;
  display: block;
}

.built-with-integrity-spotlight {
  padding: 5%;
  width: 100%;
  margin: 0 auto;
  color: $white;
  position: relative;

  .spotlight-item {
    background-position: center top;
    background-repeat: no-repeat;
    background-size: 160%;
    height: 100%;
    left: 0;
    min-height: 240px;
    position: absolute;
    top: 0;
    width: 100%;
    z-index: -1;

    &:not(:nth-of-type(1)) { display: none; }

    @media (min-width: $tablet) {
      background-size: auto 100%;
      min-height: auto;
    }

    @media (min-width: $desktop) {
      background-size: 110% auto;
      padding: 6.5% 5% 36px;
    }

    @media (min-width: 1290px) {
      background-position-y: -50px;
      background-size: 100% auto;
    }
  }

  span {
    font-family: 'Noto Sans', sans-serif;
    text-transform: uppercase;
    color: #fff;
  }

  .built, .integrity, p {
    margin-left: 55%;

    @media (max-width: $desktop) {
      margin-left: 45%;
    }

    @media (max-width: $tablet) {
      margin-left: 35%;
    }

    @media (max-width: $mobile-h) {
      margin-left: 20%;
    }
  }

  .built {
    font-size: 42px;
    font-family: 'Noto Sans', sans-serif;
    font-weight: 300;

    @media (max-width: $tablet) {
      font-size: 37px;

      @media (max-width: 440px) {
        font-size: 32px;
      }
    }
  }

  .with {
    font-size: 27px;

    @media (max-width: $tablet) {
      font-size: 23px;

      @media (max-width: 440px) {
        font-size: 17px;
      }
    }
  }

  .integrity {
    line-height: 85%;
    font-family: 'Noto Sans', sans-serif;
    font-weight: 300;
    padding-left: 30px;
    font-size: 50px;

    @media (max-width: $mobile-h) {
      padding-left: 15px;
    }

    @media (min-width: 500px) {
      font-size: 60px;
    }

    @media (min-width: $tablet) {
      font-size: 76px;
    }

    @media (min-width: 840px) {
      font-size: 84px;
    }

    @media (min-width: $desktop) {
      font-size: 70px;
      display: block;
      margin-bottom: -20px;
    }
  }

  p {
    background: none;
    color: $white;
    font-size: 95% !important;
    line-height: 135%;
    padding: 5px 35px;
    width: 45%;
    min-height: 75px;

    @media (max-width: $tablet) {
      display: none;
    }

    @media (min-width: $desktop) {
      font-size: 85%;
      padding: 25px 35px;
    }
  }
}

.home-content {
  h2 {
    @media (min-width: $tablet) {
      font-size: 55px;
    }
  }

  section.home-apps {
    margin-top: -38px;
    padding-top: 38px;
    padding-bottom: 25px;
    position: relative;
    border-bottom: 5px solid lighten($grey, 15%);
    -webkit-box-shadow: 0 5px 10px lighten($grey, 15%);
    -moz-box-shadow: 0 5px 10px lighten($grey, 15%);
    box-shadow: 0 5px 10px lighten($grey, 15%);

    + .grey-arrow-drop {
      margin-top: -5px;
    }

    .apps-background {
      position: absolute;
      left: 0;
      top: 0;
      height: 100%;
      width: 100%;
      background: url('/assets/images/home/home-app-bckgd.jpg');
      z-index: -5;
    }

    .popcorn {
      position: absolute;
      left: 0;
      top: 0;
      z-index: -5;
      width: 213px;
      height: 408px;
      background: url('/assets/images/caramel_corn.png') no-repeat;
      background-size: 90%;

      @media (max-width: $tablet) {
        display: none;
      }
    }

    .peanuts {
      position: absolute;
      right: 0;
      bottom: -65px;
      z-index: -1;
      width: 248px;
      height: 509px;
      background: url('/assets/images/peanuts.png') no-repeat;
      background-size: 80%;
      background-position-x: 100%;

      @media (max-width: $tablet) {
        display: none;
      }
    }

    ul {
      text-align: center;
      margin: 56px auto 0;
      max-width: 1400px;

      li {
        width: 75%;
        margin: 0 auto;
        text-align: center;
        padding-bottom: 50px;

        @media (min-width: $tablet) {
          max-width: 250px;
          display: inline-block;
          vertical-align: top;
        }

        @media (min-width: $desktop) {
          max-width: 290px;
        }

        .home-app-img {
          background: url('/assets/images/circle_sprite.png') no-repeat;
          height: 170px;
          width: 170px;
          display: block;
          margin: 0 auto;
          margin-bottom: 10px;

          &.roasted-nuts-app-img {
            background-position: -170px 0;
          }
          &.blanched-peanuts-app-img {
            background-position: -340px 0;
          }
          &.japanes-peanuts-app-img {
            background-position: -510px 0;
          }
          &.nut-butter-app-img {
            background-position: -680px 0;
          }
          &.chips-app-img {
            background-position: -850px 0;
          }
          &.pellets-app-img {
            background-position: -1020px 0;
          }
          &.coated-nuts-img {
            background-position: -1190px 0;
          }
        }

        &:hover {
          cursor: pointer;

          .popcorn-app-img {
            background-position: 0 -170px;
          }
          .roasted-nuts-app-img {
            background-position: -170px -170px;
          }
          .blanched-peanuts-app-img {
            background-position: -340px -170px;
          }
          .japanes-peanuts-app-img {
            background-position: -510px -170px;
          }
          .nut-butter-app-img {
            background-position: -680px -170px;
          }
          .chips-app-img {
            background-position: -850px -170px;
          }
          .pellets-app-img {
            background-position: -1020px -170px;
          }
          .coated-nuts-img {
            background-position: -1190px -170px;
          }
        }

        .home-app-heading {
          font-family: 'Noto Sans', sans-serif;
          font-size: 135%;
          color: $header-grey;
          border-bottom: 1px solid #909843;
          padding: 0 3px;
          margin: 0 auto;
          text-align: center;
          text-transform: uppercase;
        }

        p {
          padding: 0 25px;
          line-height: 115%;
          font-size: 95%;
        }
      }
    }
  }

  section.home-equipment {
    padding-top: 15px;
    border-bottom: 5px solid lighten($grey, 15%);
    -webkit-box-shadow: 0 5px 5px lighten($grey, 15%);
    -moz-box-shadow: 0 5px 5px lighten($grey, 15%);
    box-shadow: 0 5px 5px lighten($grey, 15%);

    + .blue-arrow-drop {
      margin-top: -5px;
    }

    h2 {
      line-height: 45px;
    }

    .equipment-slide {
      position: relative;
      color: #fff;
      margin-top: 23px;

      .controls {
        z-index: 999;
        position: absolute;
        top: 52%;
        right: 10px;

        @media (max-width: $mobile-h) {
          top: 28%;
        }

        @media (min-width: $tablet) {
          top: 35px;
          left: 3%;

          @media (min-width: $desktop) {
            top: 65px;
          }
        }

        > * {
          float: left;
          height: 15px;
        }

        .arrow {
          width: 16px;
          height: 16px;
          display: block;
          top: 210px;
          z-index: 1000;
          cursor: pointer;
          margin: 0 4px;
        }
        .cycle-prev {
          right: 0;
          background: url('/assets/images/equipment_browse_arrowL.gif') no-repeat;
          background-position: center center;
        }

        .cycle-next {
          background: url('/assets/images/equipment_browse_arrowR.gif') no-repeat;
          background-position: center center;
        }

        .cycle-pager {
          span {
            background: url('/assets/images/achorn_sprite.png') no-repeat;
            display: inline-block;
            background-position: -85px -10px;
            height: 16px;
            width: 15px;
            text-indent: -9999em;
            margin: 0 4px;

            &:hover {
              background-position: -68px -10px;
            }

            &:last-child {
              width: 16px;
            }

            &.cycle-pager-active {
              background-position: -68px -10px;
            }
          }
        }
      }

      > li {
        width: 100%;
        overflow: hidden;
      }

      li {
        .blue-background {
          height: 100%;
          width: 60%;
          position: absolute;

          background-image: -webkit-linear-gradient(right, #356781, #356781 85%, rgba(53, 103, 129, 0));
          background-image: -moz-linear-gradient(right, #356781, #356781 85%, rgba(53, 103, 129, 0));
          background-image: -ms-linear-gradient(right, #356781, #356781 85%, rgba(53, 103, 129, 0));
          background-image: -o-linear-gradient(right, #356781, #356781 85%, rgba(53, 103, 129, 0));
          background-image: linear-gradient(to right, #356781, #356781 85%, rgba(53, 103, 129, 0));

          @media (max-width: $tablet) {
            display: none;
          }
        }

        .info {
          @media (max-width: $mobile-h) {
            height: 74%;
          }

          @media (min-width: $tablet) {
            background: none;
          }

          h3 {
            display: inline-block;

            @media (max-width: 440px) {
              margin-top: 10px;
            }

            @media (max-width: $desktop) {
              font-size: 35px;
            }
          }
          .caption {
            margin: 15px 0 50px;
            font-size: 90%;
            line-height: 150%;
            color: #fff;

            @media (max-width: $tablet) {
              margin-bottom: 0;
              width: 70%;
            }

            @media (min-width: $tablet) and (max-width: $desktop) {
              margin-bottom: 10px;
            }

            @media (max-width: $desktop) {
              font-size: 14px;
            }
          }
          a.more {
            font-size: 95%;
            display: block;
            text-align: right;
            text-transform: uppercase;
            color: #fff;
            padding-right: 44px;
            position: relative;

            .arrow {
              @include blue-yellow-arrow;
            }

            &:hover .arrow {
              background-position: -34px 0;
            }

            @media (max-width: $tablet) {
              position: absolute;
              right: 10px;
              bottom: 10px;
            }

            @media (min-width: $tablet) {
              margin: 3% 7% 3% 3%;
            }
          }
        }
      }

      .our-stories ul > li {
        background-color: $blue;

        .info h3 { color: $grey; }
      }
    }
  }

  section.our-stories {
    padding-top: 15px;

    h2 {
      margin-bottom: 23px;
    }

    ul {
      clear: both;

      &:nth-child(even) li {
        background-color: $blue;

        .info h3 { color: $grey; }
      }

      &:nth-child(odd) li {
        background-color: $grey;

        .info h3 { color: white; }
      }
    }

    li {
      margin: 0;
      height: 400px;
      overflow: hidden;
      color: #FFF;
      position: relative;

      @media (max-width: $desktop) {
        height: 300px;
        padding: 10px 0;
      }

      @media (max-width: $tablet) {
        height: 200px;

        &:last-child {
          height: auto;
        }
      }

      &:first-child {
        @media (max-width: 440px) {
          height: 150px;
        }
      }

      @media (min-width: $tablet) and (max-width: $desktop) {
        img {
          height: 100%;
        }

        &.right-story .info {
          right: 10px;
        }

        &.left-story .info {
          left: 10px;
        }
      }

      @media (min-width: $tablet) {
        display: flex;
        width: 50%;
        align-items: center;
        justify-content: center;

        &.right-story, &.left-story { padding: 3%
        }
        &.right-story {
          float: right;
          img { left: 0 }
        }

        &.left-story {
          float: left;
          img { right: 0 }
          .info a.read-more { float: right }
        }
      }

      .info {
        color: #fff;

        @media (max-width: $tablet) {
          position: relative;
          top: auto;
          width: auto;
          margin-top: auto;
          padding: 5px 10px;
          font-size: 90%;
        }

        h3 {
          font-size: 40px;
          line-height: 45px;
          font-family: 'Noto Sans', sans-serif;
          font-weight: bold;
          border-bottom: 1px solid #FFF;
          padding-bottom: 8px;

          @media (max-width: $desktop) {
            font-size: 35px;
          }
        }

        p {
          color: #fff;
          padding: 15px 0 30px;
          line-height: 150%;

          @media (max-width: $desktop) {
            font-size: 14px;
          }
        }

        a {
          color: #FFF;
          position: relative;

          &:hover h3  { opacity: .8 }
        }
      }

      img {
        position: absolute;
        bottom: 0;

        @media (max-width: $mobile-h) {
          top: 0;
        }

        @media (max-width: $tablet) {
          bottom: -40px;
          max-width: 100%;

          @media (max-width: 500px) {
            bottom: 0;
          }
        }
      }
    }
  }
}
