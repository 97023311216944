$body-font: 'Arial';

/*** Size Styles ***/
$tablet: 767px;
$desktop: 1025px;
$mobile-h: 480px;

@mixin opacity75() {
  opacity: 0.75;
}

@mixin h3() {
  font-size: 45px;
  line-height: 45px;
  font-weight: bold;
}

@mixin blue-yellow-arrow() {
  background: url('/assets/images/achorn_sprite.png') no-repeat;
  position: absolute;
  top: -5px;
  right: 0;
  height: 34px;
  width: 34px;
}

@mixin learn-more() {
  color: $yellow !important;
  text-align: right;
  margin-right: 5%;
  padding-right: 45px;
  padding-bottom: 10px;
  position: relative;
  display: block;
  width: 100%;
  text-transform: uppercase;

  .arrow {
    @include blue-yellow-arrow;
  }

  &:hover .arrow {
    background-position: -34px 0;
  }
}

@mixin bold() {
  font-family:Arial, Helvetica, sans-serif;
  font-weight:bold;
}

@mixin a() { color:black; text-decoration: none; }
